/* bs3 grid */
.col-xs-6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
}



.ym-modal-media-info .row {
    margin:0;
}


.ym-area .modal-header .close:hover>span:before {
    color: #000;
}

/* modal */
.modal.ym-modal-media, .modal.ym-setup-modal {
    top: 50%;
    transform:translateY(-50%);
}
.ym-area .modal-body {
    box-shadow: 0px 0px 160px rgba(0,0,0,0.95);
}

/* slider */
.ym-slider:not(.ym-slider-simple) .slick-dots {
    display: block !important;
}

.ym-slider .slick-prev,
.ym-slider .slick-next {
    border:0;
    background: transparent !important;
    margin-top:-2px;
}
.ym-slider .slick-prev:before,
.ym-slider .slick-next:before {
    width:22px;
    height:22px;
    border:3px solid #000;
    border-width:3px 3px 0 0;
}
.ym-slider .slick-prev:hover:before,
.ym-slider .slick-next:focus:before {
    border-color:#000;
}

.ym-slider-simple .slick-prev:before,
.ym-slider-simple .slick-next:before {
    border:3px solid #fff;
    border-width:3px 3px 0 0;
}
.ym-slider-simple .slick-prev:hover:before,
.ym-slider-simple .slick-next:focus:before {
    border-color:#fff;
}


/* stuff */
.ym-setup-row .ym-setup-col {
    flex: 0 0 100%;
    max-width: 100%;
}



/* filter */
.ym-setup-filter .btn-filter.dropdown-toggle:after {
    display: none;
}
.ym-setup-filter .dropdown-menu {
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.ym-setup-filter .dropdown-menu .btn {
    padding:0;
}