.table table {
    width: 100% !important;
    max-width: 100% !important;
    border:0;
    margin:0 auto;
}

.table table td p,
.table table th p {
    margin: 0;
}
.table table > thead > tr > th,
.table table > tbody > tr > th,
.table table > tfoot > tr > th,
.table table > thead > tr > td,
.table table > tbody > tr > td,
.table table > tfoot > tr > td {
    padding: .5rem;
    line-height: 1.5;
    vertical-align: middle;
    text-align:center;
    background-color: #fff;
    border-top: 1px solid var(--color-light-grey);
    @media screen and (min-width: 768px){
        font-size: 1.1rem;
        padding: .75rem  1.25rem;
    }
}

.table table > thead > tr:last-child > th,
.table table > tbody > tr:last-child > th,
.table table > tfoot > tr:last-child > th,
.table table > thead > tr:last-child > td,
.table table > tbody > tr:last-child > td,
.table table > tfoot > tr:last-child > td {
    /*border-bottom: 1px solid var(--color-light-grey);*/
}
.table table > thead > tr > th {
    vertical-align: bottom;
    background: #000;
    color:#fff;
    font-family: var(--font-default-bold);
    border:0;
}

.table--align-left table td,
.table--align-left table th {
    text-align: left !important;
}
.table-bordered table {
    border:2px solid #000;
}
.table-bordered table > thead > tr > td,
.table-bordered table > tbody > tr > td,
.table-bordered table > tfoot > tr > td {
    background-color: #fff;
    border: 1px solid var(--color-light-grey);
}

.table-bordered table > tbody > tr > td:last-child {
    border-right: none;
}

.table-bordered table > tbody > tr {
    border-bottom: 1px solid var(--color-light-grey);
    border-top: 1px solid var(--color-light-grey);
}

.bg-light-grey .table-bordered table > thead > tr > td,
.bg-light-grey .table-bordered table > tbody > tr > td,
.bg-light-grey .table-bordered table > tfoot > tr > td {
    background: transparent;
    border: 1px solid var(--color-default);
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    /*border-bottom-width: 2px;*/
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: var(--color-light-grey);
}
.table-hover > tbody > tr:hover {
    background-color: var(--color-light-grey);
}
table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}
.table-responsive {
    min-height: .01%;
}
.table-swipe-col {
    display: none;
}
@media screen and (max-width: 767px) {
    .table-swipe-col {
        margin:0 !important;
        padding:0 !important;
        border:0 !important;
        display: table-cell;
    }
    .table-swipe__animation {
        position: relative;
    }
    .table-swipe__hand {
        top: .375rem;
        position: relative;
        margin-right: .75rem;
        font-size: 1.5rem;
    }
    .table-swipe__arrow {
        font-size: 1.125rem;
    }
    .swiper {
        animation-iteration-count: infinite;
        animation-duration: 1.5s;
        animation: swiper 3.6s ease infinite;
        transform-origin: 50% 50%;
    }

    @keyframes swiper {
        0% {
            transform: translateX(0);
        }
        5.55556% {
            transform: translateX(0);
        }
        11.11111% {
            transform: translateX(0);
        }
        22.22222% {
            transform: translateX(-15px);
        }
        27.77778% {
            transform: translateX(0);
        }
        33.33333% {
            transform: translateX(-15px);
        }
        44.44444% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(0);
        }
    }

    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border:2px solid #000;
    }
    .table-responsive table {
        margin-bottom: 0;
        border:0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
    .table-responsive > .table-bordered {
        border: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
    }
    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th,
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}
.table-accordion__header {
    position: relative;
}
.table-accordion__icon {
    position: absolute;
    right: 1rem;
    bottom: 0.6rem;
    transform: rotate(90deg);
    transition: transform .4s ease-in-out;
    font-weight: 800;
}
.collapsed .table-accordion__icon {
    transform: rotate(-90deg);
    transition: transform .4s ease-in-out;
}

.table--ship .table-accordion__icon {
    bottom: 3rem;
}
@media screen and (min-width: 768px) {
    .table-accordion__icon {
        right: 2rem;
        bottom: 1rem;
    }
    .table--ship .table-accordion__icon {
        bottom: 0;
    }
}
.status-legend {
    padding-bottom: calc(16rem/16);
    display: flex;
    flex-wrap: wrap;
}
.status-legend ~ .status-legend{
    padding-top: 0;
}
.status-legend_item{
    display: flex;
    align-items: center;
    position: relative;
}
.status-legend_item:not(:last-child) {
    padding-right: calc(8rem/16);
}
.status-legend_item:not(:last-child):after{
    content: "";
    position: absolute;
    right: 0;
    top: 46%;
    transform: translateY(-50%);
    width: calc(1rem/16);
    height: calc(16rem/16);
    background-color:  #2a2a2a;
}
.status-legend_item-text {
    margin-left: calc(6rem/16);
    font-size: calc(16rem/16);
    font-family: var(--font-default);
    color: var(--color-text-default);
}
.status-legend_item .icon {
    font-size: 16px;
    margin-bottom: 5px;
}

.status-legend_item .legend-icon.icon {
    margin-bottom: 0;
}

.status-legend_item-start{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    color: var(--color-text-default);
}
@media (max-width: 767px) {
    .status-legend {
        padding: calc(16rem/16);
    }
}