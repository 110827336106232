.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: none;
}


.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5 {
    margin-top:1.5rem;
    margin-bottom:1rem;
}

/* lists */
.wysiwyg ol,
.wysiwyg ul{
    list-style: none;
    padding: 0;
}
.wysiwyg ol>li,
.wysiwyg ul>li{
    padding-left: 1.5rem;
    position: relative;
    margin-bottom:.375rem;
}

.wysiwyg ul>li:before{
    content: '';
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    position: absolute;
    left:.125rem;
    top:.375rem;
    background: var(--color-text-default);

    @media screen and (min-width: 768px) {
        top:.5rem;
    }
}

.wysiwyg ol{
    counter-reset: my-counter;
}
.wysiwyg ol>li:before {
    position: absolute;
    left:0;
    top:0;
    content: counter(my-counter);
    counter-increment: my-counter;
}


.wysiwyg .floating-img {
    display: block;
    width:100%;
    margin-bottom:2rem;
    @media screen and (min-width: 768px) {
        max-width:56%;
    }
}

/* floating img list fix */
@media screen and (min-width: 768px) {
    #section-floating-img ul,
    #section-floating-img ol {
        display: table;
    }
}



.wysiwyg--multicol{
    @media screen and (min-width: 768px){
        column-count: 2;
        column-gap: 40px;
    }
}