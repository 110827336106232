.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
    clear: both;
}

.custom-checkbox--inline {
    display: inline-block;
    margin-right:2rem;
}
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #aaa;
    background: #ffffff;
    width: 18px;
    height: 18px;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(8rem/16);
    color: #fff;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    border-color: var(--color-primary);
    background: var(--color-primary);
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size:14px;
}


/* with icon */
.custom-checkbox--with-icon {
    position: relative;
    display: inline-block;
    margin:0 1%;
    width:45%;
    color:var(--color-text-muted);

    @media screen and (min-width: 768px) {
        width:17%;
    }
}
.custom-checkbox--with-icon > label {
    cursor: pointer;
}
.custom-checkbox--with-icon .custom-checkbox__box {
    display: block;
    float:none;
    margin: 80px auto 0 auto;
}
.custom-checkbox--with-icon .custom-checkbox__text {
    position: relative;
    top: -50px;
    white-space: nowrap;
    font-size:.875rem;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
.custom-checkbox--with-icon__icon {
    display:block;
    font-size:36px;
    margin:0;
    text-align: center;
    position: absolute;
    top:0;
    left:0;
    right:0;

    @media screen and (min-width: 768px) {
        font-size:40px;
    }
}

.custom-checkbox__input:checked ~ .custom-checkbox--with-icon__icon {
    color: var(--color-primary);
}