.breadcrumb-container {
    @media screen and (max-width: 767px) {
        /*display: none;*/
    }
}

.breadcrumb {
    font-size: 14px;
    background: none;
    padding:0;
    margin: 0;
    color:var(--color-text-muted);
}

.breadcrumb-item {
    color: var(--color-text-muted);
}
.breadcrumb-item .icon {
    line-height: 1.5;
}

.breadcrumb-item.active {
    color: var(--color-text-muted);
}

.breadcrumb-item:not(.active):hover {
    color: #000;
}

.breadcrumb-item+.breadcrumb-item:before {
    font-size: .875rem;
    padding-right: .5rem;
    color: var(--color-text-muted);
    content:'|';
}