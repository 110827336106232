.portalslider-teaser {
    @media screen and (min-width: 768px) {
        width:calc(100%-33px);
        margin-bottom:3.5rem;
        background-color: #fff;
    }
}

.portalslider-teaser__body {
    padding:0.5rem 1.25rem;
    line-height:1.5;
    @media screen and (min-width: 768px) {
        padding:1rem 2.25rem;
        line-height: 1.75;
    }
}
.portalslider-teaser__title {
    margin:.5rem 0;
    font-size:1.125rem;
    font-family: var(--font-default-bold);
    hyphens: auto;
    @media screen and (min-width: 768px) {
        font-size:1.875rem;
        margin:1rem 0;
    }
}

.portalslider-teaser__subtitle {
    margin: 1rem 0;
    @media screen and (max-width: 767px) {
        font-size:.875rem;
        margin: .5rem 0;
    }
}