.floating-label {
    color:#aaa;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}
.file-upload-label {
    font-size:12px;
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
input[type="date"] ~ .floating-label {
    transform: translateY(-150%) translateX(-1.25rem) scale(.65);
    color:inherit;
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-175%) translateX(-1.25rem) scale(.65);
}



/* floating label in textarea */
textarea ~ .floating-label__label {
    top:1.375rem;

    @media screen and (min-width: 768px) {
        top: 1.75rem;
    }
}