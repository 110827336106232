header {
    position: absolute;
    left:0;
    top:0;
    right:0;
    background: transparent;
    z-index:1000; /* lightgallery has 1040 */
    height:140px;

    @media screen and (max-width:767px) {
        position: fixed;
        height: 70px;
    }
    @media screen and (min-width: 768px) {
        display: flex !important;
        align-items: center;
    }
}
header .header-logo--sticky {
    display: none;
}
header.affix .header-logo--sticky {
    display: block;
}
header.affix .header-logo--default {
    display: none;
}
@media screen and (min-width: 768px) {
    header.affix .header-logo {
        transform: scale(.8);
    }

}
header.affix {
    background-color: #ffffff;
    box-shadow: -5px 1px 5px rgba(0,0,0,0.5);
    @media screen and (min-width: 768px) {
        height:90px;
    }
}
header.white:not(.affix) {
    color: #ffffff;
}

header:not(.affix)::before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(255,255,255,0) 2% , rgba(255,255,255,.2) 14%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,.95) 100%);
    top: 0;
    bottom: -35px;
    right: 0;
    left: 0;
}

header.white:not(.affix)::before {
    background: linear-gradient(0deg, rgba(0,0,0,0) 2% , rgba(0,0,0,.05) 14%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,.5) 100%);
}

header.navbar-open {
    position: fixed;
}

header ul {
    list-style: none;
    padding: 0;
}

header ul li {
    display: inline-block;
}

.header-logo {
    transition: transform .100s ease;
    height: 100%;
    max-height:120px;
    @media screen and (max-width: 767px) {
        height: 52px;
        width:auto;
        margin-top:-8px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        height: 70px;
        width:auto;
    }
}




/* Navbar top */
.navbar-top {
    width: 100%;
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 15px;
        z-index: 9;
    }
}

.navbar-top .btn.btn-sm {
    font-size: 1.1rem;
}

.navbar-top__item {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-top__item.navbar-top__item--left {
    justify-content: flex-start;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        justify-content: center;
    }
}
.navbar-top__item.navbar-top__item--right {
    justify-content: flex-end;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        justify-content: center;
    }
}

.navbar-btn-menu,
.navbar-btn-tickets{
    position: relative;
    display:inline-block;
    border:1px solid;
    padding: .5rem 1.25rem .375rem .85rem;
    z-index: 9;
    background:transparent;
    transition: all .2s linear;
    margin-right:.5rem;

    @media screen and (min-width: 768px) {
        font-size:calc(15rem / 16);
    }
}
.navbar-btn-menu:hover {
    @media screen and (min-width: 768px) {
        color: #fff;
        background: var(--color-primary);
    }

}
.navbar-btn-menu>.icon {
    margin-right: .5rem;
    position: relative;
    top: 3px;
    font-size: 17px;
    width:28px;
    display: inline-block;
}
.navbar-btn-menu.active {
    color: #fff;
    background: var(--color-primary);
}
.navbar-btn-menu.active>.icon:before {
    content: var(--icon-x); 
}
.navbar-btn-tickets{
    color:#fff;
    padding: .5rem 1.25rem .375rem 2.5rem;
    border:1px solid var(--color-primary);
    background:var(--color-primary);
    margin-right:0;
}
.navbar-btn-tickets:hover,
.navbar-btn-tickets:focus {
    color:#fff;
    border:1px solid #fff;
}
.navbar-btn-tickets > .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:.75rem;
    font-size: 21px;
}

@media screen and (max-width: 767px) {
    .navbar-top__list a.navbar-top__list-cta:not(.navbar-top__list-cta--lg),
    .navbar-btn-menu span {
        display: none;
    }
    .navbar-top__list-cta--lg>.icon {
        font-size: 20px;
        position: relative;
        top: 5px;
    }
    .navbar-btn-menu {
        padding: 0.5rem 0.9375rem 0.375rem 0.5rem;
    }
    .navbar-btn-menu>.icon {
        margin-right: 0;
        left:5px;
    }
    .navbar-btn-menu.active>.icon {
        left:8px;
    }
    .navbar-btn-tickets{
        padding: .5rem .5rem .375rem 2rem;
        line-height:1.1;
        font-size:13px;
        max-width:85px;
    }
    .navbar-btn-tickets > .icon {
        left:.5rem;
    }
}


@media screen and (min-width: 768px) {
    .navbar-top__list {
        margin:0;
    }
    .navbar-top__list-chart-img {
        display: inline-block;
        width:1.9375rem;
        height:auto;
    }
    .navbar-top__list a.navbar-top__list-cta,
    .landingpage-navbar__list a.navbar-top__list-cta {
        position: relative;
        display:inline-block;
        padding: .375rem .85rem .375rem 1.75rem;
        white-space:nowrap;
        margin-right: 0;
        transition: all .2s linear;
    }
    .navbar-top__list a.navbar-top__list-cta:hover,
    .landingpage-navbar__list a.navbar-top__list-cta:hover,
    .navbar-top__list a.navbar-top__list-cta:focus,
    .landingpage-navbar__list a.navbar-top__list-cta:focus {
        color:var(--color-primary);
    }
    .landingpage-navbar__list a.navbar-top__list-cta>.icon,
    .navbar-top__list a.navbar-top__list-cta>.icon {
        position: absolute;
        left:0;
        top:.675rem;
        font-size:1.25rem;
    }
    .navbar-top__list a.navbar-top__list-cta--lg>.icon {
        top:.375rem;
        font-size:1.5rem;
    }
    .navbar-top__list a.navbar-top__list-cta--xl>.icon {
        top:.25rem;
        left:-.5rem;
        font-size:1.875rem;
    }
}

.landingpage-navbar__list{
    width: fit-content;
    margin: 0 auto;
    @media screen and (max-width: 1199px){
        font-size: calc(14rem/16);
    }
}

@media screen and (min-width: 1600px) {
    .navbar-btn-menu{
        margin-right:1rem;
    }
    .navbar-top__list a.navbar-top__list-cta {
        margin-right: 1rem;
    }
}

/*landingpage nav*/
.landingpage-navbar__row{
    align-items: center;
}
ul.landingpage-navbar__list.landingpage-navbar__list-no-margin-md li{
    margin-right: 0;
}
@media screen and (min-width: 1600px){
    ul.landingpage-navbar__list li:not(:last-child){
        margin-right: calc(38rem/16);
    }
}
@media screen and (max-width: 767px){
    .landingpage-navbar__bottom-list{
        margin: 0 calc(30rem/16);
    }
    .landingpage-navbar__bottom-list li + li{
        margin-top: calc(20rem/16);
    }
}
