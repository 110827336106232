.offscreen-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 90%;
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
    z-index: 999;
    display: block;
    border-right:1px solid var(--color-dark);

    @media screen and (min-width: 768px) {
        width: calc(440rem / 16);
        padding-bottom: calc(70rem / 16);
    }
}
.is-open.offscreen-overlay {
    z-index: 9999;
    transform: translateX(0);
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(0,0,0,0.2);
}
.offscreen-overlay__content {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.offscreen-overlay__content__title{
    margin-bottom: .75rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
    }
}
.offscreen-overlay__content__text {
    padding:1rem;
    @media screen and (min-width: 768px) {
        padding: 2.5rem;
    }
}
.offscreen-overlay__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 52px;
    height:52px;
    text-align: center;
    color:#fff;
    background: var(--color-dark);
}
.offscreen-overlay__close__icon {
    position: relative;
    top:.875rem;
    font-size: calc(20rem / 16);
}

.offscreen-overlay__close:hover,
.offscreen-overlay__close:focus {
    color:#fff;
    background:#000;
}

/* scrollbar */
.offscreen-overlay__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: calc(6rem / 16);
}
.offscreen-overlay__content::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
}
.offscreen-overlay__content::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}

/* overlay in small map */
@media screen and (min-width: 768px) {
    .google-map .offscreen-overlay {
        position: absolute;
        width: calc(440rem / 16);
        right: 0;
        top: 0;
        bottom: 0;
    }
    .google-map .offscreen-overlay.is-open {
        transform: translateX(0);
    }
}