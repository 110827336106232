.google-map {
    position: relative;
    border:1px solid var(--color-dark) !important;
}
/*.google-map.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding-bottom: calc(55rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(70rem / 16);
    }
}*/


/* controls */
.google-map__controls {
    position: absolute;
    width: calc(40rem / 16);
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 1;

    @media screen and (min-width: 768px) {
        bottom: 2rem;
        right: 2rem;
        width: calc(50rem / 16);
    }
}
.google-map__controls__control {
    display: block;
    text-align: center;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    font-size: 1.25rem;
    border:1px solid var(--color-dark);
    background:#fff;

    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
        font-size: 1.5rem;
    }
}
.google-map__controls__control + .google-map__controls__control {
    border-top:0;
}
.google-map__controls__control:hover {
    background: var(--color-text-muted);
}
.google-map__controls__control .icon {
    position: relative;
    top:.5rem;
    @media screen and (min-width: 768px) {
        top:.625rem;
    }
}
/*.google-map__fullscreen{
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(24rem / 16);
}
.is-fullscreen .google-map__fullscreen {
    display: none;
}*/


/* close google map button
.close-mapview {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.google-map--sm:not(.is-fullscreen) .close-mapview {
    display: none;
}*/