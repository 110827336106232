.lg-toolbar {
    background: none;
}
.lg-toolbar .lg-close:after {
    content:var(--icon-x);
    font-family:iconfont;
    font-size: 1.375rem;
}
.lg-toolbar .lg-close:hover {
    color: var(--color-primary);
}

.lg-toolbar .lg-download:after {
    content:var(--icon-download);
    font-family:iconfont;
}

.lg-actions .lg-prev:after,
.lg-actions .lg-next:before {
    display: none;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
    background: none;
    font-size: calc(30rem / 16);
    color: #fff;
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: var(--color-primary);
}






/* lightgallery iframe */
.lg-has-iframe {
    max-width: 930px !important;
}