.skiline {
    width:100%;
    margin:0 auto;
    position: relative;

    @media screen and (max-width: 767px) {
        padding-top:85px;
        height:450px;
    }

    @media screen and (min-width: 768px) {
        max-width:1440px;
        height:597px;
        background:url(/static/build/img/ski-run.jpg) 50% 0 no-repeat;
    }
}
.skiline:before {
    @media screen and (max-width: 767px) {
        content:'';
        position:absolute;
        left:50%;
        top:0;
        transform: translateX(-50%);
        width:320px;
        height:51px;
        background:url(/static/build/img/audi.png) #c10a26 50% 50% no-repeat;
        background-size:320px 51px;
    }
}
.skiline__inner {

    @media screen and (min-width: 768px) {
        width: 480px;
        position: absolute;
        left:50%;
        top:13rem;
        transform: translateX(-50%);
    }
}
.skiline__iframe {
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width: 100%;
    height:597px;
    @media screen and (max-width: 767px) {
        height:450px;
    }
}