.footer {
    color: #d2d2d2;
    margin-top:0rem;
    background:var(--color-dark-grey);
    @media screen and (min-width: 768px) {
        margin-top:10rem;
        position:relative;
    }
}
/*.content-block--press-contact + .footer.footer {
    margin-top:0;
}*/
.footer a:not(.btn) {
    color: #d2d2d2;
    text-decoration: none;
}
.footer a:hover,
.footer a:focus {
    color: #fff;
}
.footer__hl {
    color:#fff;
    text-transform: uppercase;
    margin-bottom:.875rem;
}
.footer__col {
    padding:1.5rem 0;
    background:var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        padding:3rem 0;
    }
}
.footer__col--with-border {
    border:1px solid #545454;
    border-width:1px 0;

    @media screen and (min-width: 768px) {
        border-width:0 1px;
    }
}
.footer__social li.list-inline-item{
    margin: 0 .25rem;
}
.footer__social a {
    font-size: 1.625rem;
    display: inline-block;
    padding:.5rem .5rem 0 .5rem;
}
.footer__social a:hover,
.footer__social a:focus {
    color:#fff;
}






.foot {
    color: #d2d2d2;
    padding:1.5rem 0;
    border-top:1px solid #545454;
    @media screen and (max-width: 767px) {
        padding-bottom:4rem;
    }
}

.foot li.list-inline-item{
    margin:0;
}
.foot a {
    margin:.25rem;
    display: inline-block;
    padding:.25rem;
    color: #d2d2d2;
    text-decoration: none;
    @media screen and (min-width: 768px) {
        margin-right:1.75rem;
        padding:0;
    }
}
.foot a:hover,
.foot a:focus {
    color: #fff;
}
.foot .list-inline-item--elements {
    @media screen and (max-width: 767px) {
        display: block;
    }
    @media screen and (min-width: 768px) {
        float:right;
    }
}

.foot .list-inline-item--elements a {
    margin: 0;
}



.sticky-footer {
    position: fixed;
    left:-1px;
    right:0;
    bottom:0;
    background: #fff;
    height:3.75rem;
    overflow: hidden;
    border-top:1px solid #EEEEEE;
    z-index:999;
}
.sticky-footer__col {
    border-left:1px solid #EEEEEE;
}
.sticky-footer__col--info{
    position: relative;
    background: var(--color-badge-orange);
    border-color: var(--color-badge-orange) !important;
    color:#fff;
}
.sticky-footer__col--chart {
    white-space: nowrap;
}
.sticky-footer__col a {
    display: inline-block;
    font-size: calc(12rem / 16);
    height:3.75rem;
    padding:.5rem .5rem .25rem .5rem;
    text-align: center;
}
.sticky-footer__col a .icon {
    display: block;
    font-size: 1.5rem;
    height:1.5rem;
    margin-bottom:.25rem;
}
.sticky-footer__col a .icon-anlagen {
    font-size: 1.375rem;
}
.sticky-footer__col a .icon-webcams {
    font-size: 1.375rem;
}
.sticky-footer__col .sticky-footer__chart-img {
    display: block;
    width:auto;
    height:1.5rem;
    margin:0 auto .25rem auto;
}

.footer__logos{
    padding: 0 calc(20rem/16) calc(20rem/16) calc(20rem/16);
    display: flex;
    justify-content: center;
}
.footer__logos-image + .footer__logos-image{
    margin-left: calc(15rem/16);
}