.magazin-metabar__list {
    margin:0 auto;
}
.magazin-metabar__list>li {
    margin:0 !important;
}
.magazin-metabar a.blog-search,
.magazin-metabar__list a {
    display: inline-block;
    padding: 0 0 .125rem 0;
    margin:0 .5rem;
    border: 0;
    border-radius: 0;
    font-size:.875rem;
    font-family: var(--font-default-bold);
    color: var(--color-text-muted);
    border-bottom:4px solid transparent;

    @media screen and (min-width: 768px) {
        font-size:1.25rem;
        margin:0 1.5rem;
    }
}
.magazin-metabar a.blog-search:hover, .magazin-metabar a.blog-search:focus,
.magazin-metabar__list a.active, .magazin-metabar__list a:hover, .magazin-metabar__list a:focus {
    color: var(--color-text-default);
    background-color: transparent;
    border: 0;
    border-bottom:4px solid var(--color-primary);
}

@media screen and (max-width: 767px) {
    .magazin-metabar a.blog-search  {
        margin-top: 1rem;
    }
}
.magazin-metabar a.blog-search>.icon {
    margin-right:.5rem;
    position: relative;
    top:.25rem;
    @media screen and (max-width: 767px) {
        top:.125rem;
    }
}



/* category dropdown */
.dropdown--magazin-categories a>.icon {
    font-size:2rem;
    position: relative;
    top:.5rem;
    margin-left:.375rem;
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        top:.625rem;
    }
}
.dropdown--magazin-categories .dropdown-menu {
    padding:1rem 2.125rem;
    left:-2rem !important;
    margin: 0;
    border:0;
    border-radius:0;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
}
.dropdown--magazin-categories .dropdown-item {
    width: auto;
    padding:0;
    margin:.5rem auto;
    font-family: var(--font-default);
}


.blog-teaser-lg {
    margin:2rem 0;

    @media screen and (min-width: 768px) {
        margin:2.5rem 0 8rem 0;
    }
}


.author-teaser {
    border-top:1px solid #aaa;
    padding:2rem 0 0 0;
    margin-top:2rem;
    @media screen and (min-width: 768px) {
        padding:5rem 0 0 0;
        margin-top:5rem;
    }
}



.magazin-detail-content-wrapper {
    position: relative;
    padding-bottom:1rem;
    @media screen and (min-width: 1200px) {
        padding-bottom:8rem;
    }
}

@media screen and (min-width: 1200px) {
    .magazin-detail-content-wrapper:before,
    .magazin-detail-content-wrapper:after {
        content:'';
        position: absolute;
        top:-3rem;
        bottom:0;
        left:0;
        width:1px;
        height:auto;
        background:#000;
    }
    .magazin-detail-content-wrapper:after {
        top:100%;
        left:0;
        width:50%;
        height:1px;
    }
}

@media screen and (min-width: 1600px) {
    .magazin-detail-content-wrapper:before {
        left:40px;
    }
    .magazin-detail-content-wrapper:after {
        left:40px;
        width:calc(50% - 40px);
    }
}

.magazin-detail-end {
    position: relative;
    width:auto;
    max-width:680px;
    margin:50px 15px 90px 15px;
    border:1px solid #000;
    background: #fff;
    padding:1.5rem;
    @media screen and (min-width: 768px) {
        margin:177px auto 140px auto;
        padding:4rem;
    }
}
@media screen and (min-width: 1200px) {
    .magazin-detail-end__border {
        width:1px;
        height:178px;
        background:#000;
        top:-178px;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
    }
}

.magazin-detail-end__quote {
    position: relative;
    font-family: var(--font-default-bold);
    font-size: 2.5rem;
    line-height: 1px;
}