.breadcrumb-container + .content-block,
.content-block + .content-block,
.pimcore_area_content + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: 3rem;
    @media screen and (min-width: 768px) {
        margin-top: 7.5rem;
    }
}

/* small */
.breadcrumb-container + .content-block.content-block--sm,
.content-block + .content-block.content-block--sm,
.pimcore_area_content + .content-block.content-block--sm,
.content-block + .pimcore_area_content > .content-block.content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block.content-block--sm {
    margin-top: 1.75rem;
    @media screen and (min-width: 768px) {
        margin-top: 4rem;
    }
}


.content-block--no-space {
    margin-top:0 !important;
}


.content-block--top-lg {
    padding-top:2rem;
    @media screen and (min-width: 768px) {
        padding-top:4rem;
    }
}

.content-block--bottom-lg {
    padding-bottom:1rem;
    @media screen and (min-width: 768px) {
        padding-bottom:3rem;
    }
}


.content-block--portal-sliderblock {
    background-position: 50% 50%;
    background-size: cover;
}
.portal-sliderblock__background-img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
    @media screen and (min-width: 768px){
        top: calc(130rem/16);
        height: calc(100% - calc(130rem/16));
    }
}
@media screen and (min-width: 768px) {
    .content-block--portal-sliderblock {
        margin:-130px 0 130px 0;
        padding:0 0 130px 0;
        /*padding:0 0 150px 0;
        position: relative;
        top:-180px;*/
        /*margin-bottom:180px;*/
        background-position: 50% 130px;
    }
    .portal-sliderblock + .portal-sliderblock {
        margin-top:130px;
    }
}

.section-headings {
    margin-bottom:1rem;
    text-align:center;
    @media screen and (min-width: 768px) {
        margin-bottom:2rem;
    }
}
/*.section-headings + .wysiwyg {
    text-align:center;
}*/
.section-headings .section-heading__h1,
.section-headings .section-heading__h2 {
    margin-bottom:1rem;
}

.section-heading__icon {
    position: relative;
    top:-1px;
    font-size:80%;
    margin-right:.5rem;
}
