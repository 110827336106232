/* text accordion */
.accordion {
    position: relative;
    padding:0;
    margin:0;
    @media screen and (min-width: 768px) {

    }
}
.accordion .card {
    position: relative;
    margin:0;
    width:100%;
    background-color: transparent;
    border:0;
    border-radius:0;
}
.accordion .card-header {
    padding: 0;
    margin:0;
    background-color: transparent;
    border-radius:0;
    border-bottom: 0;
}

.accordion .card-header>button {
    width: 100%;
    border: none;
    text-align: left;
}
.accordion .card-header>button:focus {
    outline: none;
}

.accordion .card-header>a,
.accordion .card-header>button{
    display: block;
    position: relative;
    color:var(--color-text-default);
    padding:1rem 2rem 1rem 1rem;
    margin:0;
    font-size:1rem;
    font-family: var(--font-default-bold);
    text-decoration: none;
    background-color: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        padding:1rem 2rem 1rem 1.5rem;
        margin:0 2.5rem;
    }
}
.accordion.accordion--presseimages .card-header>a,
.accordion.accordion--presseimages .card-header>button {
    margin: 0;
}
.accordion .card-header>a.collapsed,
.accordion .card-header>button.collapsed {
    background-color:#fff;
    border-bottom:1px solid var(--color-text-default);
}
.accordion .card:first-child .card-header>a,
.accordion .card:first-child .card-header>button {
    border-top:1px solid var(--color-text-default);
}
.accordion .card-header>a:hover,
.accordion .card-header>a:focus,
.accordion .card-header>button:hover,
.accordion .card-header>button:focus {
    text-decoration: none;
    color:var(--color-dark);
}
.accordion .card-header>a:after,
.accordion .card-header>button:after {
    content:'';
    position: absolute;
    right:1rem;
    top:1.5rem;
    width: 10px;
    height:10px;
    border:1px solid var(--color-primary);
    border-width:1px 1px 0 0;
    transform: rotate(-45deg);
    transition: transform .25s ease;
    @media screen and (min-width: 768px) {
        top:1.875rem;
    }
}
.accordion .card-header>a.collapsed:after,
.accordion .card-header>button.collapsed:after {
    transform: rotate(135deg);
    top:1.25rem;
    @media screen and (min-width: 768px) {
        top:1.5rem;
    }
}
.accordion .card-body {
    padding:0 1rem 1.75rem 1rem;

    @media screen and (min-width: 768px) {
        padding-left:1.5rem;
    }
}

.accordion.accordion--presseimages .card-body {
    background-color: var(--color-light-grey);
}

.container--accordion{
    @media screen and (max-width: 768px) {
        padding: 0;
    }
}