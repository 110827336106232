.nav-tabs {
    border: 0;
}
.nav-tabs--with-margin {
    margin:1.5rem 0;

    @media screen and (min-width: 768px) {
        margin:3.5rem 0;
    }
}
.nav-tabs--narrow {
    @media screen and (min-width: 768px) {
        width:100%;
        max-width:700px;
        margin-left:auto;
        margin-right:auto;
    }
}
.nav-tabs .nav-link {
    display: inline-block;
    padding: 0 0 .125rem 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    font-size:.875rem;
    font-family: var(--font-default-bold);
    color: var(--color-text-muted);
    border-bottom:4px solid transparent;

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
    }
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: var(--color-text-default);
    background-color: transparent;
    border: 0;
    border-bottom:4px solid var(--color-primary);
}


/* arrival tabs */
.nav-tabs--arrival {

}
.nav-tabs--arrival .nav-link {
    position: relative;
    width:80px;
    height:80px;
    text-align: center;
    padding-top:15px;
    margin: 0 .5rem;
    background:#fff;
    border:1px solid var(--color-text-default) !important;
    border-radius:50%;
    @media screen and (min-width: 768px) {
        padding-top:45px;
        width:188px;
        height:188px;
        margin: 0 4rem;
    }
}
.nav-tabs--arrival .nav-link .icon {
    font-size: 1.5rem;
    display: block;
    margin: 2px auto;

    @media screen and (min-width: 768px) {
        font-size: 3rem;
    }
}
.nav-tabs--arrival .nav-item.show .nav-link .active-state, .nav-tabs--arrival .nav-link.active .active-state {
    position: absolute;
    top:100%;
    left:50%;
    width:21px;
    height:21px;
    margin:-12px 0 0 -10px;
    transform: rotate(-45deg);
    background:#fff;
    border:1px solid var(--color-text-default);
    border-width:0 0 1px 1px;

    @media screen and (min-width: 768px) {
        width:39px;
        height:39px;
        margin:-23px 0 0 -19px;
    }
}

.nav-tabs--arrival .nav-item.show .nav-link:after, .nav-tabs--arrival .nav-link.active:after {
    content:'';
    position: absolute;
    top:100%;
    left:50%;
    width:2000px;
    height:3px;
    background:#fff;
    margin-top:2.875rem;
    margin-left:1px;

    @media screen and (min-width: 768px) {
        margin-top:79px;
    }
}
.nav-tabs--arrival .nav-item.show .nav-link:before, .nav-tabs--arrival .nav-link.active:before {
    content:'';
    position: absolute;
    top:100%;
    left:50%;
    width:1px;
    height:3rem;
    background:var(--color-text-default);

    @media screen and (min-width: 768px) {
        height:5rem;
    }
}
.arrival-tab {
    border: 1px solid #2a2a2a;
    border-right:0;
    margin-top: 3rem;
    padding: 1.5rem;
    /*background-color: #fff;*/
    @media screen and (min-width: 768px) {
        margin-top: 5rem;
        padding: 3.5rem 3rem;
    }
}
.arrival-tab__content {
    width: 100%;
    max-width:940px;
    margin:0 auto;
}

@media screen and (min-width: 768px) {

}