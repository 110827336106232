.jobs-img-grid{
    margin: 5rem 0;
    position: relative;
}

.jobs-img-grid__item:after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
}
.jobs-img-grid__item:hover:after {
    position: absolute;
    content: '';
    left: 0;
    top: 35%;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    transition: ease-in-out 250ms;
}

.jobs-img-grid__item__text{
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
    color: white;
    z-index: 2;
}

.jobs-img-grid__item__text .field {
    display: block;
    text-transform: uppercase;
    font-size: calc(32rem/16);
    font-family: var(--font-default-bold);
    line-height: 1.1;
}

.jobs-img-grid__item__text .number {
    font-size: calc(72rem/16);
    font-family: var(--font-default-bold);
    line-height: 0;
}

.jobs-img-grid__item__text .offers {
    font-size: calc(16rem/16);
    max-width: calc(65rem/16);
    display: inline-block;
    line-height: 1.2;
    padding-left: 0.75rem;
}

.jobs-benefits {
    margin: 3rem 0;
}

.jobs-contact {
    display: inline-block;
    border: 1px solid var(--color-default);
    padding: 8rem 7rem 3rem 7rem;
    position: relative;
    margin: 6rem 0;
    background:#fff;
    @media screen and (max-width: 767px){
        padding: 6rem 1rem 3rem 1rem;
    }
}

.jobs-contact__image{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 11rem;
    width: 11rem;
    border-radius: 100%;
}

.dropdown.jobs-dropdown>.dropdown-toggle{
    position: relative;
    padding: 1.5rem 5rem 1.5rem 2rem;
    border: none !important;
    @media screen and (max-width: 767px){
        padding: 1.5rem 5rem 1.5rem 1rem;
    }
}

.jobs-dropdown.jobs-dropdown--duration>.dropdown-toggle{
    @media screen and (min-width: 768px){
        margin-left:-2rem;
    }
}
.jobs-dropdown>.dropdown-toggle:after {
    color: var(--color-primary);
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 6rem;
}

.jobs-dropdown .jobs-dropdown__title {
    display: block;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.1;
}

.jobs-dropdown .jobs-dropdown__category {
    display: block;
    text-align: left;
    font-size: 1.5rem;
    font-family: var(--font-default-bold);
    line-height: 1.1;
}

.jobs-dropdown .dropdown-menu {
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: 5;
    border-bottom: none;
    box-shadow: 0px 5px 25px rgba(0,0,0,.25);
}

.jobs-dropdown .dropdown-menu .dropdown-item{
    padding: 1rem 10rem 1rem 1rem;
    font-size: 1.125rem;
    min-width: 23rem;
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor:pointer;
    @media screen and (max-width: 767px){
        padding:  0.5rem 6rem 0.5rem 1rem;
        min-width: 18rem;
    }
}

.jobs-item{
    padding:1.5rem 2rem;
    font-size: 18px;
    min-height: calc(75rem/16);
    border-bottom: 1px solid var(--color-default);
    border-left: 1px solid var(--color-default);
    border-right: 1px solid var(--color-default);

    @media screen and (max-width: 767px){
        padding:1rem;
    }
}

/* jobs accordion */

.accordion.jobs-accordion{
    margin-top: 3rem;
}

.accordion.jobs-accordion .card-header>a {
    color: var(--color-white);
    margin: 0;
    text-align: left;
    background: transparent;
    padding: 0.5rem 2rem 0.5rem 1rem;
    border: none;
    font-family: var(--font-default-bold);
}

.accordion.jobs-accordion .card .card-header>a {
    border: none;
    background: var(--color-dark-grey);
}

.accordion.jobs-accordion .card-body{
    border-bottom: 2px solid var(--color-dark-grey);
    border-left: 2px solid var(--color-dark-grey);
    border-right: 2px solid var(--color-dark-grey);
    padding: 1.25rem;
    @media screen and (max-width: 767px){
        padding: 0.25rem;
    }
}

.accordion.jobs-accordion .card-header>a:after,
.accordion.jobs-accordion .card-header>a.collapsed:after {
    display: none;
}

.jobs-accordion__header.collapsed .icon-addthis {
    transform: translateY(-50%);
    transition: ease-in-out 0.25s;
}

.jobs-accordion__header .icon-addthis {
    color: var(--color-primary);
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: ease-in-out 0.25s;
}

.jobs-accordion__list {
    list-style-type: square;
    color: var(--color-primary);
    @media screen and (max-width: 767px){
        padding-left: 1.5rem;
    }
}

.jobs-accordion__list .jobs-accordion__list--item {
    color: var(--color-default);
}

.jobs-accordion__list li{
    padding: 0.25rem 0.5rem;
}