.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 9999;
}

@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
}

.cookie-bar__left{
    width: 100%;
    @media screen and (max-width: 767px){
        text-align:center;
    }
}

.cookie-bar__text,
.cookie-bar__detail{
    display: inline-block;
}

@media screen and (min-width: 768px){
    .cookie-bar__text{
        margin-right: calc(15rem/16);
    }
}
.cookie-bar__detail-link{
    text-decoration: underline;
}

.cookie-bar__detail-link +.cookie-bar__detail-link{
    margin-left: calc(15rem/16);
}

.cookie-bar__detail-link:hover{
    text-decoration: none;
}


.cookie-bar__detail-btn-no-style{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}
.btn.cookie-bar__settings-btn {
    background:rgb(239, 239, 239);
    color:#000;
} 

.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin:0 0 0 1rem;
        text-align: right;
    }
}


.cookie-bar__accept {
    margin-left: .75rem;

@media screen and (max-width: 767px){
    font-size: calc(14rem/16);
}
}

/* modal */

.cookie-modal .modal-header .close{
    padding: calc(2rem/16) calc(15rem/16) calc(8rem/16);
    margin: 0;
}

.cookie-modal .modal-header .close:focus{
    outline: none;
}

.cookie-modal .modal-header .close>span{
    display: inline-block;
    line-height: 1;
}

.cookie-modal__item {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    position: relative;
    top:.125rem;
    font-size: 1.125rem;
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    font-size:.875rem;
    text-decoration: underline;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-text-default);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-text-default);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}

[hidden]{
    display: none!important;
}
