body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: 18px;
    line-height: 1.75;

    @media screen and (max-width: 767px) {
        font-size: 15px;
    }
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.font-default.font-default {
    font-family: var(--font-default) ;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    display: block;
}

h1, .h1 {
    font-size: calc(60rem/16);
    line-height: 1.125;
    font-family: var(--font-default-bold);
}
h2, .h2 {
    font-size: calc(50rem/16);
    line-height: 1.125;
    font-family: var(--font-default-bold);
}
h3, .h3 {
    font-size: calc(30rem/16);
}
h4, .h4 {
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
}
h5, .h5 {
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
}
h6, .h6 {
    font-size: 1rem;
}


@media screen and (max-width: 767px) {
    h1, .h1 {
        font-size: 1.75rem;
    }
    h2, .h2 {
        font-size: 1.5rem;
    }
    h3, .h3 {
        font-size: 1.375rem;
    }
    h4, .h4 {
        font-size: 1.25rem;
    }
    h5, .h5 {
        font-size: 1.125rem;
    }
}