.fancy-teaser-slides {
    margin: 0 -10px;
    @media screen and (min-width: 768px) {
        margin: -20px -33px 0 -33px;
    }
}
@media screen and (min-width: 768px) {
    .fancy-teaser-slides .slick-list {
        padding-top: 20px;
    }
}
.fancy-teaser-slides .slick-slide {
    margin: 0 5px;
    @media screen and (min-width: 768px) {
        margin:60px 33px 0 33px;
    }
}
.fancy-teaser-slides .slick-slide:nth-child(2n+1) {
    @media screen and (min-width: 768px) {
        margin:20px 33px 0 33px;
    }
}




.teaser {
    position: relative;
    transition: all .2s linear;
    margin-bottom: 1.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 3.5rem;
    }
}
@media screen and (min-width: 768px) {
    .teaser:hover {
        transform: translateY(-12px);
        transition: all .2s linear;
    }
    .teaser--download:hover {
        transform: translateY(0);
    }
    .teaser--download:hover .embed-responsive {
        transform: scale(1.15);
    }
}
.teaser__body {
    font-size:14px;
    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.teaser__title {
    margin:1.25rem 0 1rem 0;
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:1rem;
        margin:.75rem 0 .5rem 0;
        hyphens: auto;
    }
}
.teaser__title--small {
    font-size:1.125rem;
    margin:1rem 0 .5rem 0;
    @media screen and (max-width: 767px) {
        font-size:.75rem;
        margin:.5rem 0 .25rem 0;
    }
}
@media screen and (min-width: 768px) {
    .teaser__body--lg {
        font-size:1.125rem;
    }
    .blog-teaser-lg__title,
    .teaser__body--lg .teaser__title {
        font-size:2.25rem;
        line-height:1;
    }
}


.teaser-category {
    display: inline-block;
    position: relative;
    padding:.125rem 0 .125rem 1.75rem;
}
.teaser-category>.icon {
    position: absolute;
    left:0;
    top:.125rem;
    font-size:1.25rem;
}
.teaser-category>.icon-kinder-familien {
    top:.5rem;
    font-size:.75rem;
}


.teaser-icon-list a {
    display: block;
    position: relative;
    padding:.125rem 0 .125rem 1.5rem;
}
.teaser-icon-list a>.icon {
    position: absolute;
    left:0;
    top:.25rem;
    font-size:1.125rem;
}
.teaser__badge {
    position: absolute;
    left:0;
    bottom:0;
    z-index:5;
    line-height: 1;
    padding:.625rem 1.125rem .375rem 1.125rem;
    color:#fff;
    font-size:13px;

    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}


.teaser__badge--top {
    bottom:auto;
    top:0;
}









/* large img text teaser */
.img-text-teaser {
    padding:2rem 0;
    @media screen and (max-width: 767px) {
        padding:0 2rem;
    }
}
.img-text-teaser--portalslider {
    @media screen and (max-width: 767px) {
        padding:5rem 2rem;
    }
}
.img-text-teaser__sm-img {
    position: absolute;
    bottom:-70px;
    right:-50px;
    @media screen and (max-width: 767px) {
        bottom:-20px;
        right:-22px;
        width:120px;
    }
}

.img-text-teaser__sm-img.img-text-teaser__sm-img--left{
    left:-50px;
    right: auto;
    @media screen and (max-width: 767px) {
        left:-22px;
    }
}

.img-text-teaser__lg-img {
    @media screen and (max-width: 767px) {
        margin-left:-1.5rem;
    }
}