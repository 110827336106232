/*.slick-list {
    overflow-x: visible;
}
.slick-track {
    overflow-x: visible;
}*/




.slider:not(.slick-slider)>div+div{
    display: none;
}

.slider {
    position: relative;
}
.slider--lh1 {
    line-height:1px;
}

/* arrows */
.slick-arrow {
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    color: var(--color-text-default);
    width:54px;
    height:54px;
    background: transparent;
    border-radius:0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    z-index: 10;
    border:1px solid #000;

    @media screen and (max-width: 767px) {
        width:40px;
        height:40px;
    }
}

.slick-arrow.slick-disabled {
    /*display: none !important;*/
    cursor: not-allowed;
    opacity: .35;
}

.slick-prev {
    right:auto;
    left:0;

    @media screen and (max-width: 767px) {
        left:0;
    }
}
.slick-arrow:before {
    position: relative;
    left: 8px;
    display: block;
    content:'';
    width:16px;
    height:16px;
    border:1px solid var(--color-text-default);
    border-width:1px 1px 0 0;
}
.slick-prev:before {
    left: 16px;
    transform: rotate(-135deg);
    @media screen and (max-width: 767px) {
        left: 10px;
    }
}
.slick-next:before {
    transform: rotate(45deg);
    @media screen and (max-width: 767px) {
        left: 2px;
    }
}

.slick-arrow:hover,
.slick-arrow:focus {
    background: #000 !important;
}
.slick-arrow:hover:before,
.slick-arrow:focus:before {
    border-color: #fff;
}


.add-arrows {
    position: relative;
}
.add-arrows--with-count {
    position: absolute;
    right:0;
    top:0;
    width:107px;
    height:54px;

    @media screen and (max-width: 767px) {
        width:79px;
        height:62px;
    }
}


/* slider-count */
.slider-count {
    position: relative;
    text-align: right;
    padding-right:130px;
    @media screen and (max-width: 767px) {
        padding-right:105px;
    }
}
.slider-count__current {
    font-family: var(--font-default-bold);
    font-size:2.5rem;
}




@media screen and (min-width: 1540px) {
    .slider--wide-arrows .slick-prev {
        left:-54px;
        border-right:0;
    }
    .slider--wide-arrows .slick-next {
        right:-54px;
        border-left:0;
    }
}



/* with simple arrows */
.slider--simple-arrows .slick-arrow {
    border:0;
    background: #fff !important;
    margin-top:-2px;
}
.slider--simple-arrows .slick-arrow:before {
    width:12px;
    height:12px;
    border:3px solid var(--color-text-muted);
    border-width:3px 3px 0 0;
}

.slider--simple-arrows .slick-arrow:hover:before,
.slider--simple-arrows .slick-arrow:focus:before {
    border-color:var(--color-text-default);
}



/* with simple arrows large */
.slider--simple-arrows-lg .slick-arrow {
    border:0;
    background: transparent !important;
    margin-top:-2px;
}
.slider--simple-arrows-lg .slick-arrow:before {
    width:44px;
    height:44px;
    border:3px solid #fff;
    border-width:3px 3px 0 0;
}
.slider--simple-arrows-lg .slick-next:before {
    left: auto;
    right: 20px;
}
.slider--simple-arrows-lg .slick-arrow:hover:before,
.slider--simple-arrows-lg .slick-arrow:focus:before {
    border-color:#fff;
}




.slider--hide-arrows .slick-arrow {
display: none !important;
}
.slick-dots {
display: none !important;
}