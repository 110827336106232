.gallery .slick-slide {
    padding:.125rem;
}
@media screen and (min-width: 768px) {
    .gallery-caption {
        opacity: 0;
        transition: all .2s linear;
    }
    .slick-current .gallery-caption {
        opacity: 1;
    }
}

@media screen and (max-width: 767px) {
    .gallery-caption {
        display: none;
    }
}