.pressrelease-teaser {
    margin:4rem 0;
    @media screen and (max-width: 767px) {
        margin:2.5rem 0;
    }
}

.pressrelease-teaser__title {
    margin:1.25rem 0 1rem 0;
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:1rem;
        margin:.125rem 0 .5rem 0;
    }
}

.pressrelease-teaser__meta {
    font-size:1rem;
    margin:1rem 0 .5rem 0;
    @media screen and (max-width: 767px) {
        font-size:.75rem;
        margin:.5rem 0 .25rem 0;
    }
}