.guest-chart {
    position: relative;
    height:344px;
    width:150px;
    /*background: #00b050;*/
    background: linear-gradient(0deg, rgba(0,176,80,1) 0%, rgba(0,176,80,1) 70%, rgba(255,192,0,1) 78%, rgba(255,192,0,1) 86%, rgba(255,0,0,1) 100%);

    margin:0 auto;
    border-top:2px solid #ff0000;
}
/*.guest-chart__top,
.guest-chart__middle {
    position: relative;
    height:43px;
    width:150px;
    background: #ffc000;
}
.guest-chart__top {
    background: #ff0000;
    height:41px;
}*/
.guest-chart__current {
    position: absolute;
    left:-1.5rem;
    right:-3.75rem;
    text-align: right;
    border-bottom:1px solid #000;
}