@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743167648953/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743167648953/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743167648953/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-alm:before { content: "\EA02" }
.icon-anlagen:before { content: "\EA03" }
.icon-anlagen_bahn:before { content: "\EA04" }
.icon-anlagen_funslope:before { content: "\EA05" }
.icon-anlagen_gondel:before { content: "\EA06" }
.icon-anlagen_movie:before { content: "\EA07" }
.icon-anlagen_schlepplift:before { content: "\EA08" }
.icon-anlagen_schmidolin:before { content: "\EA09" }
.icon-anlagen_sessellift:before { content: "\EA0A" }
.icon-anlagen_touren:before { content: "\EA0B" }
.icon-anlagen_wandern:before { content: "\EA0C" }
.icon-anlagen_zauberteppich:before { content: "\EA0D" }
.icon-apre-ski:before { content: "\EA0E" }
.icon-arrow-right:before { content: "\EA0F" }
.icon-arrow:before { content: "\EA10" }
.icon-ausstellung:before { content: "\EA11" }
.icon-auto:before { content: "\EA12" }
.icon-bar:before { content: "\EA13" }
.icon-berggasthaus:before { content: "\EA14" }
.icon-bus:before { content: "\EA15" }
.icon-cafe:before { content: "\EA16" }
.icon-chart:before { content: "\EA17" }
.icon-check:before { content: "\EA18" }
.icon-circle:before { content: "\EA19" }
.icon-detail:before { content: "\EA1A" }
.icon-download:before { content: "\EA1B" }
.icon-dropdown:before { content: "\EA1C" }
.icon-elements:before { content: "\EA1D" }
.icon-facebook:before { content: "\EA1E" }
.icon-fact-duration:before { content: "\EA1F" }
.icon-fact-gondel:before { content: "\EA20" }
.icon-fact-group:before { content: "\EA21" }
.icon-fact-poi:before { content: "\EA22" }
.icon-fact-scissors:before { content: "\EA23" }
.icon-fb:before { content: "\EA24" }
.icon-flickr:before { content: "\EA25" }
.icon-fullscreen:before { content: "\EA26" }
.icon-genuss-station:before { content: "\EA27" }
.icon-gondel:before { content: "\EA28" }
.icon-google-plus:before { content: "\EA29" }
.icon-insta:before { content: "\EA2A" }
.icon-internet:before { content: "\EA2B" }
.icon-jobs-app:before { content: "\EA2C" }
.icon-jobs-arbeitsbekleidung:before { content: "\EA2D" }
.icon-jobs-fahrtkostenzuschuss:before { content: "\EA2E" }
.icon-jobs-mahlzeiten:before { content: "\EA2F" }
.icon-jobs-mitarbeiterpraemien:before { content: "\EA30" }
.icon-jobs-mitarbeiterunterkuenfte:before { content: "\EA31" }
.icon-jobs-sportverein:before { content: "\EA32" }
.icon-jobs-weiterbildung:before { content: "\EA33" }
.icon-jobs-yoga:before { content: "\EA34" }
.icon-kalender:before { content: "\EA35" }
.icon-kinder-familien:before { content: "\EA36" }
.icon-kinderpiste:before { content: "\EA37" }
.icon-kulinarik-genuss:before { content: "\EA38" }
.icon-linkedin:before { content: "\EA39" }
.icon-load-orange:before { content: "\EA3A" }
.icon-load-red:before { content: "\EA3B" }
.icon-load:before { content: "\EA3C" }
.icon-location:before { content: "\EA3D" }
.icon-logout:before { content: "\EA3E" }
.icon-magazin:before { content: "\EA3F" }
.icon-mail:before { content: "\EA40" }
.icon-map:before { content: "\EA41" }
.icon-menue:before { content: "\EA42" }
.icon-minus:before { content: "\EA43" }
.icon-open:before { content: "\EA44" }
.icon-pinterest:before { content: "\EA45" }
.icon-piste:before { content: "\EA46" }
.icon-play:before { content: "\EA47" }
.icon-rennstrecke:before { content: "\EA48" }
.icon-restaurant:before { content: "\EA49" }
.icon-route:before { content: "\EA4A" }
.icon-schiffahrt:before { content: "\EA4B" }
.icon-schnee:before { content: "\EA4C" }
.icon-sessel-lift:before { content: "\EA4D" }
.icon-sonne:before { content: "\EA4E" }
.icon-sport-spaß:before { content: "\EA4F" }
.icon-sprache:before { content: "\EA50" }
.icon-status-gesperrt:before { content: "\EA51" }
.icon-status-offen:before { content: "\EA52" }
.icon-status:before { content: "\EA53" }
.icon-suche:before { content: "\EA54" }
.icon-swipe:before { content: "\EA55" }
.icon-tagestipp:before { content: "\EA56" }
.icon-temp:before { content: "\EA57" }
.icon-ticket:before { content: "\EA58" }
.icon-time:before { content: "\EA59" }
.icon-tradition:before { content: "\EA5A" }
.icon-trend:before { content: "\EA5B" }
.icon-twitter:before { content: "\EA5C" }
.icon-unterhaltung:before { content: "\EA5D" }
.icon-wanderweg:before { content: "\EA5E" }
.icon-weather:before { content: "\EA5F" }
.icon-webcams:before { content: "\EA60" }
.icon-wetter-0:before { content: "\EA61" }
.icon-wetter-1:before { content: "\EA62" }
.icon-wetter-10:before { content: "\EA63" }
.icon-wetter-2:before { content: "\EA64" }
.icon-wetter-3:before { content: "\EA65" }
.icon-wetter-4:before { content: "\EA66" }
.icon-wetter-5:before { content: "\EA67" }
.icon-wetter-6:before { content: "\EA68" }
.icon-wetter-7:before { content: "\EA69" }
.icon-wetter-8:before { content: "\EA6A" }
.icon-wetter-9:before { content: "\EA6B" }
.icon-whatsapp:before { content: "\EA6C" }
.icon-x:before { content: "\EA6D" }
.icon-yt:before { content: "\EA6E" }
.icon-zauberteppich:before { content: "\EA6F" }
.icon-zug:before { content: "\EA70" }


:root {
--icon-addthis: "\EA01";
    --icon-alm: "\EA02";
    --icon-anlagen: "\EA03";
    --icon-anlagen_bahn: "\EA04";
    --icon-anlagen_funslope: "\EA05";
    --icon-anlagen_gondel: "\EA06";
    --icon-anlagen_movie: "\EA07";
    --icon-anlagen_schlepplift: "\EA08";
    --icon-anlagen_schmidolin: "\EA09";
    --icon-anlagen_sessellift: "\EA0A";
    --icon-anlagen_touren: "\EA0B";
    --icon-anlagen_wandern: "\EA0C";
    --icon-anlagen_zauberteppich: "\EA0D";
    --icon-apre-ski: "\EA0E";
    --icon-arrow-right: "\EA0F";
    --icon-arrow: "\EA10";
    --icon-ausstellung: "\EA11";
    --icon-auto: "\EA12";
    --icon-bar: "\EA13";
    --icon-berggasthaus: "\EA14";
    --icon-bus: "\EA15";
    --icon-cafe: "\EA16";
    --icon-chart: "\EA17";
    --icon-check: "\EA18";
    --icon-circle: "\EA19";
    --icon-detail: "\EA1A";
    --icon-download: "\EA1B";
    --icon-dropdown: "\EA1C";
    --icon-elements: "\EA1D";
    --icon-facebook: "\EA1E";
    --icon-fact-duration: "\EA1F";
    --icon-fact-gondel: "\EA20";
    --icon-fact-group: "\EA21";
    --icon-fact-poi: "\EA22";
    --icon-fact-scissors: "\EA23";
    --icon-fb: "\EA24";
    --icon-flickr: "\EA25";
    --icon-fullscreen: "\EA26";
    --icon-genuss-station: "\EA27";
    --icon-gondel: "\EA28";
    --icon-google-plus: "\EA29";
    --icon-insta: "\EA2A";
    --icon-internet: "\EA2B";
    --icon-jobs-app: "\EA2C";
    --icon-jobs-arbeitsbekleidung: "\EA2D";
    --icon-jobs-fahrtkostenzuschuss: "\EA2E";
    --icon-jobs-mahlzeiten: "\EA2F";
    --icon-jobs-mitarbeiterpraemien: "\EA30";
    --icon-jobs-mitarbeiterunterkuenfte: "\EA31";
    --icon-jobs-sportverein: "\EA32";
    --icon-jobs-weiterbildung: "\EA33";
    --icon-jobs-yoga: "\EA34";
    --icon-kalender: "\EA35";
    --icon-kinder-familien: "\EA36";
    --icon-kinderpiste: "\EA37";
    --icon-kulinarik-genuss: "\EA38";
    --icon-linkedin: "\EA39";
    --icon-load-orange: "\EA3A";
    --icon-load-red: "\EA3B";
    --icon-load: "\EA3C";
    --icon-location: "\EA3D";
    --icon-logout: "\EA3E";
    --icon-magazin: "\EA3F";
    --icon-mail: "\EA40";
    --icon-map: "\EA41";
    --icon-menue: "\EA42";
    --icon-minus: "\EA43";
    --icon-open: "\EA44";
    --icon-pinterest: "\EA45";
    --icon-piste: "\EA46";
    --icon-play: "\EA47";
    --icon-rennstrecke: "\EA48";
    --icon-restaurant: "\EA49";
    --icon-route: "\EA4A";
    --icon-schiffahrt: "\EA4B";
    --icon-schnee: "\EA4C";
    --icon-sessel-lift: "\EA4D";
    --icon-sonne: "\EA4E";
    --icon-sport-spaß: "\EA4F";
    --icon-sprache: "\EA50";
    --icon-status-gesperrt: "\EA51";
    --icon-status-offen: "\EA52";
    --icon-status: "\EA53";
    --icon-suche: "\EA54";
    --icon-swipe: "\EA55";
    --icon-tagestipp: "\EA56";
    --icon-temp: "\EA57";
    --icon-ticket: "\EA58";
    --icon-time: "\EA59";
    --icon-tradition: "\EA5A";
    --icon-trend: "\EA5B";
    --icon-twitter: "\EA5C";
    --icon-unterhaltung: "\EA5D";
    --icon-wanderweg: "\EA5E";
    --icon-weather: "\EA5F";
    --icon-webcams: "\EA60";
    --icon-wetter-0: "\EA61";
    --icon-wetter-1: "\EA62";
    --icon-wetter-10: "\EA63";
    --icon-wetter-2: "\EA64";
    --icon-wetter-3: "\EA65";
    --icon-wetter-4: "\EA66";
    --icon-wetter-5: "\EA67";
    --icon-wetter-6: "\EA68";
    --icon-wetter-7: "\EA69";
    --icon-wetter-8: "\EA6A";
    --icon-wetter-9: "\EA6B";
    --icon-whatsapp: "\EA6C";
    --icon-x: "\EA6D";
    --icon-yt: "\EA6E";
    --icon-zauberteppich: "\EA6F";
    --icon-zug: "\EA70";
    
}