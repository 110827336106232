.badge-round {
    transform: rotate(20deg);
    text-align: center;
    line-height: 1.2;
    width: 200px;
    height:200px;
    padding: 1rem;
    transition: transform .15s linear;
    color: #ffffff;
    background:url(/static/img/bg-badge.svg);
    background-size: cover;

    @media screen and (max-width: 767px) {
        width: 150px;
        height:150px;
        font-size: .875rem;
    }
}

.badge-round--alpin-card-en,
.badge-round--alpin-card-de {
    @media screen and (max-width: 767px) {
        width: 100px;
        height:100px;
        margin-right:20px;
    }
}

.badge-round--orange {
    background:url(/static/img/bg-badge-orange.svg);
    background-size: cover;
}
.badge-round--alpin-card-en {
    background:url(/static/img/alpin-card-EN.png);
    background-size: cover;
    transform: rotate(0);

    @media screen and (max-width: 767px) {
        width: 110px;
        height:110px;
    }
}
.badge-round--alpin-card-de {
    background:url(/static/img/alpin-card-DE.png);
    background-size: cover;
    transform: rotate(0);
}
.badge-round:hover {
    transform: rotate(14deg);
}
.badge-round__inner {
    border: 1px solid;
    border-radius: 50%;
    padding: 5px;
    width: 174px;
    height:174px;
    position: absolute;
    left:50%;
    top:50%;
    transform:translateY(-50%) translateX(-50%);
    overflow:hidden;

    @media screen and (max-width: 768px) {
        width: 124px;
        height:124px;
    }
}
.badge-round__body {
    padding:0;
    position: absolute;
    left:0;
    right:0;
    top:50%;
    transform:translateY(-50%);
}
.badge-round__heading {
    padding:10px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    border-bottom: 1px solid;
}
.badge-round__link {
    display: block;
    padding:5px 15px;
    border-bottom: 1px solid;
}