.shop-teaser-wide {
    position: relative;
}
.shop-teaser-wide__img {
    height:20rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.shop-teaser-wide__body {
    position: relative;
    width: 90%;
    text-align: center;
    max-width:35rem;
    padding:4.5rem 2.5rem 2.5rem 2.5rem;
    margin: -120px auto 0 auto;
    color:#fff;
    background: var(--color-primary);
}
.shop-teaser-wide__icon {
    position: absolute;
    left:50%;
    top:0;
    transform: translateX(-50%) translateY(-50%);
    background:#fff;
    font-size: 2rem;
    border-radius: 50%;
    width: calc(100rem / 16);
    height: calc(100rem / 16);
    color:var(--color-primary);
}
.shop-teaser-wide__icon .icon {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}