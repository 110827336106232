.quick-filter {
    margin-bottom: .625rem;
    box-shadow: none;
    background: #fff;
    color: inherit;
    font-size: calc(15rem / 16);
    position: relative;
    padding: calc(4rem / 16) calc(30rem / 16) calc(2rem / 16) calc(14rem / 16);
    cursor: pointer;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
    border:1px solid var(--color-primary);
}
.quick-filter:hover {
    background: var(--color-primary);
    color: #fff;
}
.quick-filter:hover .quick-filter__icon {
    color: #fff;
}
.quick-filter:not(:last-child) {
    margin-right: calc(10rem / 16);
}
.quick-filters--right .quick-filter {
    margin-left: calc(10rem / 16);
    margin-right: 0;
}
.quick-filter__icon {
    font-size: calc(10rem / 16);
    position: absolute;
    color: var(--color-primary);
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}