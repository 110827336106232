.video-js .vjs-big-play-button.vjs-big-play-button,
.play-btn{
    border-radius: 50%;
    padding: .125rem 0 0 .125rem;
    border: none;
    font-size: 1rem;
    text-shadow: 0 0 10px rgba(0,0,0,.3);
    height: 70px;
    width: 70px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-primary);
    box-shadow: 1px 1px 0 0 #000000;
    text-align: center;
}
.play-btn {
    display: block;
}
.video-js:hover .vjs-big-play-button.vjs-big-play-button,
.play-btn:hover{
    background: var(--color-primary-dark);
}
.video-js .vjs-big-play-button:before,
.play-btn:before {
    content: var(--icon-play);
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    color:#fff;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
    display: none;
}


@media screen and (max-width: 767px) {
    .video-js .vjs-big-play-button.vjs-big-play-button,
    .play-btn{
        font-size: .875rem;
        height: 35px;
        width: 35px;
    }
}