.badge-teaser {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    border-top: 100px solid;
    border-right: 100px solid transparent;
}
.badge-teaser--orange {
    border-top-color: var(--color-badge-orange) !important;
}
.badge-teaser--primary {
    border-top-color: var(--color-primary);
}
.badge-teaser--alert {
    border-top-color: var(--color-danger);
}

.badge-teaser__text {
    position: absolute;
    top: -63px;
    left: -13px;
    width: 100px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    font-size:1rem;
    line-height:1px;
    font-family: var(--font-default-bold);
    transform: rotate(-45deg);
}

/* badge teaser kulinarik */

.badge-premium{
    position: absolute;
    height: 4.5rem;
    width: 4.5rem;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
}

