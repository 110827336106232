.webcam-map__overlays {
    position: absolute;
    left:0;
    top:0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.15);
}
.webcam-map__item {
    width: 140px;
    height: 120px;
    display: inline-block;
    position: absolute;
    text-align: center;
    margin: -70px 0 0 -60px;
}
.webcam-map__item--cam10 {
    left:63%;
    top:48%;
}
.webcam-map__item--cam9 {
    left:54%;
    top:37%;
}
.webcam-map__item--cam8 {
    left:72%;
    top:51%;
}
.webcam-map__item--cam7 {
    left:46%;
    top:37%;
}
.webcam-map__item--cam6 {
    left:29%;
    top:52%;
}
.webcam-map__item--cam5 {
    left:82%;
    top:55%;
}
.webcam-map__item--cam4 {
    left:48%;
    top:97%;
}
.webcam-map__item--cam3 {
    left:67%;
    top:95%;
}
.webcam-map__item--cam1 {
    left:19%;
    top:60%;
}
.webcam-map__item--cam2 {
    left:72%;
    top:65%;
}
.webcam-map__item--cam11 {
    left:15%;
    top:79%;
}
.webcam-map__item--cam12 {
    left:6%;
    top:79%;
}
.webcam-map__item--cam13 {
    left:48%;
    top:82%;
}
.webcam-map__item--cam14 {
    left:40%;
    top:82%;
}
.webcam-map__item--cam15 {
    left:44%;
    top:68%;
}
.webcam-map__item--cam16 {
    left:91%;
    top:60%;
}
.webcam-map__item--cam17 {
    left: 33%;
    top: 73%;
}

.webcam-map__img {
    width:70px;
    height:70px;
    margin: 0 auto;
    position: relative;
}
.webcam-map__img:before {
    position: absolute;
    bottom:0;
    right:0;
    content:'';
    display: inline-block;
    width: 22px;
    height:22px;
    background: var(--color-primary);
    border-radius: 50%;
    box-shadow: 1px 1px 0 0 #000000;
    z-index:4;
}
.webcam-map__img:after {
    position: absolute;
    bottom:7px;
    right:7px;
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #fff;
    z-index:6;
}
.webcam-map__img-img {
    margin:0 auto;
    display: inline-block;
    width: 100%;
    height:auto;
    border-radius: 50%;
    border:4px solid #fff;
    box-shadow: 1px 1px 0 0 #000000;
}
.webcam-map__img--valley .webcam-map__img-img {
    border:4px solid #ffc000;
}

.webcam-map__title {
    display: block;
    color:#fff;
    font-size: 14px;
    line-height:1.1;
    margin-top:4px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    font-family: var(--font-default-bold);
}


.webcam-map__item:hover .webcam-map__img:before {
    background: var(--color-primary-dark);
}