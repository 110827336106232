.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}
.custom-radio:after {
    clear: both;
}
.custom-radio--inline {
    display: inline-block;
    margin-right:2rem;
}
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #aaa;
    background: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__box {
    border-color: var(--color-primary);
    /*background: var(--color-primary);*/
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}


.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    font-size:14px;
}


@media screen and (min-width: 768px) {
    /* large */
    .custom-radio--lg .custom-radio__text {
        font-size:1.125rem;
    }

    .custom-radio--lg .custom-radio__box {
        width: 28px;
        height: 28px;
        margin-right: .75rem;
    }
}