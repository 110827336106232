.toc {
    text-align: center;
    position: fixed;
    left:0;
    top:0;
    right:0;
    z-index:9999;
}
.toc a{
    display: inline-block;
    padding:10px;
    color:#fff;
    background: var(--color-primary);
}