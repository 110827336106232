.mainimg-breadcrumb-container {
    margin-bottom:2rem;
    @media screen and (min-width: 768px) {
        margin-bottom:3rem;
    }
}
.main-img-container {
    position: relative;
    margin-bottom:1rem;

    @media screen and (min-width: 768px) {
        margin-bottom:1.5rem;
    }
}
@media screen and (min-width: 768px) {
    .main-img-zoom-container {
        position: relative;
        overflow: hidden;
        height:100vh;
        transition: all 10s linear;
    }
    .main-img-zoom-container.start-zoom .main-img {
        transform: scale(1.2);
        transition: all 10s linear;
    }
}

.main-img-container.main-img-container--with-portal-sliderblock {
    margin:0;
}
.main-img {
    position: relative;
    height:75vh;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: 768px) {
        height:100vh;
    }
}
.main-img video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    min-width:1920px;
    height: auto;
    @media (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
        min-width:2350px;
    }
}
.main-img--has-video {
    overflow: hidden;
    @media screen and (min-width: 1600px) and (min-height: 1000px) {
        height:72vh;
    }
}
.main-img + .main-img__box-shadow {
    position: absolute;
    width: 100%;
    height: 40%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
}

.main-img__overlay {
    position: absolute;
    bottom: 1rem;
    left:0;
    right:0;
    pointer-events:none;
    z-index:1;

    @media screen and (min-width: 1800px) {
        bottom:3.125rem;
    }
}

.main-img__overlay.main-img__overlay--with-arrow {
    bottom:3.5rem;

    @media screen and (min-width: 768px) {
        bottom:7rem;
    }
}
.main-img__overlay.main-img__overlay--with-arrow.no-arrow-mobile {
    @media screen and (max-width: 767px) {
        bottom:2rem;
    }
}
.main-img__overlay--with-arrow:after,
.main-img__overlay--with-arrow:before {
    content:'';
    display: block;
    width:14px;
    height:14px;
    border:3px solid #fff;
    border-width:0 0 3px 3px;
    transform: rotate(-45deg);
    position: absolute;
    left:50%;
    bottom:-1.875rem;
    @media screen and (min-width: 768px) {
        bottom:-3rem;
    }
}
.main-img__overlay--with-arrow:before {
    bottom:-1.25rem;
    @media screen and (min-width: 768px) {
        bottom:-3.75rem;
    }
}
.no-arrow-mobile.main-img__overlay--with-arrow:after,
.no-arrow-mobile.main-img__overlay--with-arrow:before{
    @media screen and (max-width: 767px) {
        display: none
    }
}

@media screen and (min-width: 768px) {
    .main-img-container--with-portal-sliderblock .main-img__overlay {
        bottom:200px;
    }
}
.main-img__content {
    width:100%;
    max-width:600px;
    pointer-events:initial;
}
.main-img__main-headline {
    color: #fff;
    line-height:1.1;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.5);
}
@media screen and (min-width: 1440px) {
    .main-img__main-headline.main-img__main-headline--xxl {
        font-size:80px;
    }
}
.main-img__sub-headline {
    color: #fff;
    font-size:1.125rem;
    font-family: var(--font-default);
    margin-top:.25rem;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.5);
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        margin-top:.5rem;
    }
}
.main-img__content .btn  {
    margin-top:.25rem;
    @media screen and (min-width: 768px) {
        margin-top:1rem;
    }
}


/* mainimg small */
.main-img.main-img--small {
    height:55vh;
    width: 100%;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .main-img-container--small .main-img__main-headline {
        font-size:2.5rem;
    }
    .main-img-container--small .main-img-zoom-container {
        height:55vh;
    }
}




/* mainimg cols */
@media screen and (min-width: 768px) {
    .main-img-cols-container {
        position: relative;
        width:66.666666%;
    }
    .main-img--cols {
        width:100%;
        height:calc(100vh - 140px);
    }
    .main-img-zoom-container--cols {
        width:100%;
        height:100%;
    }
    .main-img__right {
        padding:1rem 2rem;
        text-align: center;
        position: absolute;
        right: 0;
        top:50%;
        transform: translateY(-50%);
        width:33.333333%;
    }
}

@media screen and (max-width: 767px) {
    .main-img__right {
        padding:1rem;
    }
}
.main-img__col-rounded {
    width:300px;
    height:300px;
    position: relative;
    margin:1rem auto -1rem auto;
    border-radius: 50%;
    border: 20px solid #EEEEEE;
    text-align: center;
}
.main-img__col-rounded--with-txt {
    border: 28px solid var(--color-primary);
}
.main-img__col-rounded-txt {
    position: absolute;
    top:50%;
    left:25px;
    right:25px;
    transform: translateY(-55%);
}

@media screen and (min-width: 768px) {
    .main-img__col-rounded-txt .h1 {
        font-size:120px;
        line-height:100px;
    }
}

/* mainimg badge */

.main-img__badge {
    position: absolute;
    top: 20%;
    right: 3%;

    @media screen and (max-width: 767px) {
        right:-8px;
    }
}

.main-img__badge.bottom-mobile {
    @media screen and (max-width: 768px) {
        right:1rem;
        top: unset;
        bottom: -2.75rem;
    }
}