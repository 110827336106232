html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}

body {
    margin: 0;
    position: relative;
    background-color: #EEEEEE;
    overflow-x: hidden;
}

body.ov-hidden {
    overflow: hidden;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
        overflow-x: auto;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
    .container.container--xs-full {
        padding: 0;
    }
}

.page-wrapper {
    position: relative;
    width:100%;
    margin:0 auto;
    /*max-width:1920px;*/
    background-color: #fff;
    background-image: url(/static/img/bg.png), url(/static/img/bg.png), url(/static/img/bg.png), url(/static/img/bg.png);
    background-position: 20% 0, 40% 0, 60% 0, 80% 0;
    background-repeat: repeat-y, repeat-y, repeat-y, repeat-y;
    box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
    padding-top:140px;

    @media screen and (max-width: 767px) {
        background-image: url(/static/img/bg.png), url(/static/img/bg.png);
        background-position: 33.333% 0, 66.666% 0;
        background-repeat:repeat-y, repeat-y;
        padding-top:70px;
        overflow-x: hidden;
    }
}
.page-wrapper.page-wrapper--portal {
    padding-top:0;
}

.container.container {
    max-width: calc(1470rem/16);
    width: 100%;
}
.container.container--narrow {
    max-width: calc(1090rem/16);
}
.container.container--extra-narrow {
    max-width: calc(640rem/16);
}
.container.container--wide {
    max-width: calc(1800rem/16);
}