.modal-header .close {
    display: block;
    width:54px;
    height:54px;
    margin:0;
    position: absolute;
    right:0;
    top:0;
    background:#000;
    color:#fff;
    opacity: 1;
    text-align: center;
    z-index: 10;
}
.modal-header .close>span {
    display: block;
    text-indent: -19999px;
}
.modal-header .close>span:before {
    font-family: iconfont;
    content:var(--icon-x);
    color:#fff;
    text-indent:0;
    font-size:1rem;
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
}
.modal-footer {
    text-align: center;
}
.modal-title {
    text-align: center;
    font-size: calc(50rem/16);
    line-height: 1.25;
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: 1.5rem;
    }
}

/* cookie modal */
.cookie-modal .modal-title {
    font-size: calc(32rem/16);
}


/* modal flyout */
@media screen and (min-width: 768px) {
    .modal--flyout .modal-dialog {
        margin: 0;
        position: absolute;
        right:0;
        top:0;
        bottom:0;
        background: #fff;
    }
    .modal--flyout .modal-body {
        padding:4.5rem;
    }
}