/* Navbar left */
.navbar-left__wrapper {
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-110%);
    transition: transform .250s ease;

    @media screen and (min-width: 768px) {
        max-width: 1920px;
    }
}
.navbar-left__wrapper.is-open {
    transform: translateX(0);
}

.navbar-left {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 30%;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);
    @media screen and (max-width: 767px) {
        width: 100%;
    }

}

/* main-nav */
.main-nav {
    height: 100%;
    position: relative;
    background-color: #ffffff;
    padding-top: 120px;

    @media screen and (max-width: 767px) {
        padding: 0;
        margin-top: 75px;
        overflow-x: hidden;
    }
}
.main-nav ul > li {
    display: block;
    padding: 0;
}
.main-nav a {
    display: block;
    color:var(--color-text-default);
    font-size: 1.2rem;

    @media screen and (max-height: 750px) and (min-width: 768px) {
        padding: .5rem 1rem;
    }
    @media screen and (max-width: 767px) {
        padding: .5rem 2rem;
    }
    @media screen and (min-width: 768px) {
        position: relative;
        padding: 1rem;
    }
    @media screen and (min-width: 1200px) and (min-height: 751px) {
        font-size: 1.6rem;
    }
    @media screen and (min-width: 1800px) {
        padding-left:75px;
        padding-right:60px;
    }
}
.main-nav a:hover,
.main-nav a:focus,
.main-nav ul > li:not(.sub-nav__title):hover > .has-subnav__wrapper,
.main-nav ul > li:not(.sub-nav__title):focus > .has-subnav__wrapper,
.main-nav ul > li:hover > a,
.main-nav ul > li:hover > a,
.sub-nav ul > li:hover > a,
.sub-nav ul > li:focus > a {
    @media screen and (min-width: 768px) {
        color: var(--color-primary);
    }
}

.main-nav ul > li:hover > .sub-nav,
.main-nav ul > li:focus > .sub-nav {
    @media screen and (min-width: 768px) {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        border-right: 1px solid rgba(0,0,0,0.1);
    }
}
.main-nav ul > li > .sub-nav,
.main-nav ul > li > .sub-nav {
    @media screen and (min-width: 768px) {
        opacity: 0;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }
}
.sub-nav ul > li:hover > .sub-nav,
.suv-nav ul > li:focus > .sub-nav {
    @media screen and (min-width: 768px) {
        transition: transform 250ms ease-in-out, opacity 500ms ease-in-out;
    }
}

.has-subnav__wrapper > a,
.sub-nav__title > a{
    width: calc(100% - 80px);
    display: inline-block;
}

.has-subnav__wrapper {
    position: relative;
    cursor: pointer;
}
.has-subnav__arrow {
    position: absolute;
    right: .5rem;
    top: -.325rem;
    height: 100%;
    width: 50px;
    text-align: center;

    @media screen and (min-width: 768px) {
        height: auto;
        width: auto;
        top: 50%;
        right: 50px;
        transform: translateY(-50%);
    }
}
.has-subnav__arrow .icon {
    transform: rotate(180deg);
    font-size:14px;
    color:var(--color-text-default);
    position:relative;
    top:-2px;

    @media screen and (max-width: 767px) {
        font-size:18px;
        transform: translateY(100%) rotate(180deg);
    }
}

.sub-nav {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-left: 1px solid rgba(0,0,0,0.1);
    transform: translateX(0%);
    transition: transform .15s ease;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
    /*border-bottom:30px solid red;*/

    @media screen and (min-width: 768px) {
        width: 85%;
        transform: translateX(-100%);
        overflow: inherit;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        visibility: visible;
        z-index: -1;
        border-bottom:0;
        padding-top:120px;
    }

}

.sub-nav.is-open {
    visibility: visible;
    transform: translateX(-100%);

    @media screen and (max-width: 767px) {
        padding-bottom:8rem;
    }
}
.main-nav .sub-nav a {
    font-size: 1rem;
    line-height:1.5;
    @media screen and (min-width: 768px) {
        padding: 10px;
    }
    @media screen and (min-width: 992px) and (min-height: 751px) {
        font-size: 1.15rem;
    }
    @media screen and (min-width: 1800px) {
        padding-left:60px;
        padding-right:60px;
    }
    @media screen and (max-height: 750px) and (min-width: 768px) {
        padding: 6px 10px;
        font-size: .875rem;
    }
}

.sub-nav__title {
    background-color: var(--color-light-grey);
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}
.sub-nav__title:before {
    position: absolute;
    left: 1rem;
    top: .625rem;
    content: var(--icon-arrow);
    display: inline-block;
    margin-right: 10px;
    font-family: iconfont;
    font-weight: bold;
    color:var(--color-text-default);
}
.sub-nav__title > a {
    display: inline-block !important;
    font-size: 1.15rem !important;
    padding: .75rem .75rem .5rem 2.75rem !important;
}
.sub-nav__title > a:hover,
.sub-nav__title > a:focus {
    color: inherit;
}
