.content-circle {
    position: relative;
    border-radius: 50%;
    border: 15px solid var(--color-text-default) !important;
    text-align: center;

    @media screen and (min-width: 768px) {
        border: 22px solid var(--color-text-default) !important;
    }
}
.content-circle__inner {
    position: absolute;
    top:50%;
    left:25px;
    right:25px;
    transform: translateY(-55%);
}
.content-circle__nr {
    font-size: 2.75rem;
    line-height: 1.25;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        font-size: 3.25rem;
    }
}
.content-circle__txt {
    font-size:1rem;
    line-height:1;
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}