.weather-container {
    padding:2rem 1.5rem;
    border:1px solid #000;
    background:#fff;
    position:relative;
    z-index:5;

    @media screen and (min-width: 768px) {
        padding:6rem 4rem;
    }
}


.weather-box {
    position: relative;
    line-height:1;
    margin:1rem auto;
    width:100%;
    min-width:250px;
    text-align: center;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
        max-width:414px;
    }

    @media screen and (max-width: 767px) {
        margin:2rem auto;
    }
}

.weather-box.weather-box--forecast {
    max-width:250px;
}

.weather-box--forecast .weather-box__day{
    margin-bottom: 1.5rem;
}

.weather-box--forecast .weather-box__icon {
    margin-bottom: 0.65rem;
    height: 95px;
    @media screen and (min-width: 768px){
        height: 162px;
        margin-bottom: 2rem;
    }
}
.weather-box__forecast .icon {
    position: relative;
    margin:0 .25rem .25rem .25rem;
    top:.125rem;
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

/*.weather-box .weather-box__col:nth-child(2):before,
.weather-box .weather-box__col:nth-child(2):after{
    border:1px solid #000;
    border-width:0 1px;
}*/
.weather-box__col--md-break {
    @media screen and (max-width: 1200px) {
        width:100%;
        display:block;
    }
}

.weather-box .weather-box__col--border{
    border:1px solid #000;
    border-width:0 1px 0 0;

    @media screen and (min-width: 1200px) {
        border-width:0 1px;
    }
}

.weather-box .weather-box__col--border-always {
    border:1px solid #000;
    border-width:0 1px;
}
.weather-box__icon {
    text-align: center;
    font-size:5rem;
    margin-bottom:1rem;

    @media screen and (min-width: 768px) {
        font-size:10rem;
    }
}

.weather-box__forecast{
    margin-bottom:.25rem;
    margin-top:.25rem;
}

.weather-box__icon>img {
    display: inline-block;
    margin:0 auto;
    width:5rem;
    height:5rem;

    @media screen and (min-width: 768px) {
        width:11rem;
        height:11rem;
    }
}
.weather-box__temp {
    font-size:2rem;
    margin-right:1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        font-size:3rem;
        margin-bottom: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
        font-size:2.25rem;
    }
}
.weather-box__temp .icon {
    font-size:3rem;
    position: relative;
    margin-right:.5rem;
    @media screen and (max-width: 1400px) {
        font-size:2.25rem;
    }
}


.weather-box__snow,
.weather-box__sunshine {
    margin-bottom:.25rem;
    margin-top:.25rem;
    @media screen and (min-width: 768px) {
        font-size:1.375rem;
    }
}
.weather-box__snow .icon,
.weather-box__sunshine .icon {
    position: relative;
    margin-right:.5rem;
    top:.125rem;

    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}


.weather-box__day {
    margin-bottom: 1.5rem;
    line-height: 1;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
        font-size:1.375rem;
    }
}