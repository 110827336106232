.zitat {
    border:1px solid #000;
    background: #fff;
    padding:4rem;
    text-align: center;
}
.zitat__quote {
    position: relative;
    font-family: var(--font-default-bold);
    font-size: 2.5rem;
    line-height: 1px;
}
.zitat__inner {
    position: relative;
    margin:0 auto;
    width: 100%;
    max-width:850px;
    font-size:1.25rem;
}
.zitat__name {
    font-family: var(--font-default-bold);
    letter-spacing: 2px;
    font-size:1.5rem;
}


@media screen and (max-width: 767px) {
    .zitat {
        padding:1.5rem;
    }
    .zitat__quote {
        font-size: 1.75rem;
    }
    .zitat__inner {
        font-size:1rem;
    }
    .zitat__name {
        font-size:1.25rem;
    }
}