/* mainimg */
@media screen and (min-width: 768px) {
    .connect-top {
        position: absolute;
        width: 1400px;
        right:50%;
        top:100%;
        margin-top:40px;
        height:300px;
        z-index:99;
        border:1px solid #000;
        border-width:0 1px 1px 0;
        pointer-events: none;
    }

    .line-connector {
        padding:8rem 0 .25rem 0;
        /*background:#cecece;*/
    }
}
@media screen and (min-width: 1680px) {
    .connect-bottom {
        content:'';
        display: block;
        width:600px;
        height:250px;
        position: absolute;
        left:0;
        top:100%;
        border:1px solid #000;
        border-width:0 0 1px 1px;
        pointer-events: none;
    }
}


/*connect*/
.connect-container {
    position:relative;
}
@media screen and (min-width: 1680px) {
    .connect-left-bottom,
    .connect-right-top {
        display: block;
        position: absolute;
        height:120vh; 
        min-height:500px;
        width:400px;
        border:1px solid #000;
        z-index:4;
        pointer-events: none;
    }
    .connect-right-top {
        right:-110px;
        top:-91vh;
        border-left:0;
    }
    .connect-left-bottom {
        left:-110px;
        top:50%;
        height:580px;
        border-right:0;
    }
}