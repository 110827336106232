.embed-responsive.embed-responsive-3by2:before {
    padding-top: 66.666666667%;
}
.embed-responsive-high:before {
    padding-top: 133.333333%
}

.embed-responsive-webcam-map:before {
    padding-top: 68.05555555555556%;
}

.embed-responsive-livecam:before {
    padding-top: 56.28787878787879%;
}

.embed-responsive-skiline:before {
    padding-top: 90%;

    @media screen and (min-width: 768px) {
        padding-top: 41.45833333333333%;
    }
}

.embed-responsive-map:before {
    padding-top: 150%;

    @media screen and (min-width: 768px) {
        padding-top: 45%;
    }
}