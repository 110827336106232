.btn.btn {
    border-radius: 0;
    border-width:1px;
    padding:.75rem 1.25rem .625rem 1.25rem;
    font-size:inherit;
    text-decoration: none;
    font-family: var(--font-default);
    transition: all .2s linear;
    line-height:1;

    @media screen and (min-width: 768px) {
        padding:1.125rem 2.5rem 1rem 2.5rem;
    }
}
.btn:hover,
.btn:focus {
    text-decoration: none !important;
}
.btn.btn-sm {
    font-size:.875rem;
    padding:.5rem 1rem;

    @media screen and (min-width: 768px) {
        padding:.75rem;
    }
    @media screen and (min-width: 992px) {
        padding:.75rem 1.5rem;
    }
}
.btn.btn-lg {
    font-size:1.25rem;
    padding:1rem 2rem;

    @media screen and (min-width: 768px) {
        padding:1.25rem 3rem;
    }
}
.btn-primary:focus {
    background:var(--color-primary);
}
.btn-primary:hover,
.btn-primary:focus {
    background:var(--color-primary-dark);
    border-color:var(--color-primary-dark);
}
.btn-default {
    border-color:var(--color-dark-grey);
    background:var(--color-dark-grey);
}
.btn-default:hover,
.btn-default:focus {
    background:#000;
    border-color:#000;
}


.btn.btn-link {
    border-color:transparent;
    color:inherit;
    background: transparent;
}
.btn.btn-link:hover,
.btn.btn-link:focus {
    background: transparent;
    border-color:inherit;
}

.btn.btn-outline {
    color: inherit;
    border-color: inherit;
    background:transparent;
}
.btn.btn-outline:hover,
.btn.btn-outline:focus {
    color:#fff;
    background:#000;
}

.btn.btn-outline-white {
    color:#fff;
    border-color:#fff;
    background:transparent;
}
.btn.btn-outline-white:hover,
.btn.btn-outline-white:focus {
    color:#000;
    background:#fff;
}

.btn.btn-transparent {
    border: none;
    color: inherit;
    background: transparent;
}
.btn.btn-transparent:hover,
.btn.btn-transparent:focus {
    color: var(--color-primary);
}

.btn.btn-with-icon {
    @media screen and (min-width: 768px) {
        padding: .5rem .85rem;
    }
}

.btn-with-icon .icon {
    margin-right: .5rem;
    position: relative;
    top: 3px;
}

.btn-with-icon--lg .icon {
    display: none;
    margin-right: 0;

    @media screen and (min-width: 992px) {
        display: inline-block;
        margin-right: .5rem;
    }
}


/* scroll top btn */
.scroll-top-btn {
    position: fixed;
    bottom: 70px;
    right:15px;
    width:40px;
    height: 40px;
    display: block;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.33);
    z-index:99;
}
.scroll-top-btn:before {
    transform: rotate(-45deg);
    position: absolute;
    left: 12px;
    top:15px;
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #2a2a2a;
    border-width: 1px 1px 0 0;
}

.btn.btn.btn-icon{
    padding: 16px;
    background: var(--color-light-grey);
    display: flex;
    align-items: center;
}