/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-sustainability{
    color: #36a828!important;
}
.nav-text--red{
    color: #731f1a!important;
}
a.text-sustainability:hover,
a.nav-text--red:hover{
    @media screen and (min-width: 768px) {
        color: var(--color-primary)!important;
    }
}

.fs125 {font-size:1.25rem !important;}
.fs175 {font-size:1.75rem !important;}
.fs15 {font-size:1.5rem !important;}
.fs25 {font-size:2.5rem !important;}
.fs1 {font-size:1rem !important;}
.fs2 {font-size:2rem !important;}
.fs425 {font-size:4.25rem !important;}
.lh-2 {
    line-height:2;
}
.lh-1 {
    line-height:1;
}

@media screen and (min-width: 768px){
    .item-center-md {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    .vertical-center-md {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.text-bold,
.font-bold {
    font-family: var(--font-default-bold);
    font-weight: 700;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-blue { color:var(--color-info-blue); }
.text-red { color:var(--color-danger); }
.text-black { color:#000; }
.text-orange {color:var(--color-badge-orange)}
.text-green {color:#00b050}

/*Background Color Helper*/
.bg-dark-grey {background:var(--color-dark-grey);}
.bg-light-grey {background:var(--color-light-grey);}
.bg-secondary {background:var(--color-secondary);}
.bg-primary-light {background:var(--color-primary-light);}
.bg-orange {background:var(--color-badge-orange)}


.anchor-target:target {
    display: block;
    margin-top: calc(-100rem / 16);
    padding-top: calc(100rem / 16);
    /*z-index: -999;*/
}

.tooltip-link {
    margin:0 .5rem;
    width:18px;
    height:18px;
    line-height:18px;
    font-size:12px;
    color:#fff !important;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    background:var(--color-primary);
}
.tooltip-inner p {
    margin:0;
}


.bordered-container {
    padding:2rem 1.5rem;
    border:1px solid #000;
    background:#fff;
    position:relative;
    z-index:5;

    @media screen and (min-width: 768px) {
        padding:6rem 4rem;
    }
}

.backlink {
    font-family: var(--font-default-bold);
    position: relative;
}
.backlink:hover,
.backlink:focus {
    color:var(--color-primary);
}
.backlink .icon {
    position: relative;
    font-size:.875rem;
    top: 2px;
}

.isClickable {
    cursor: pointer;
}

.ov-x-hidden {overflow-x: hidden}

.hidden {
    display: none !important;
}

.iframe-scroll-overlay {
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:rgba(0,0,0,.5);
    z-index:50;
}
.iframe-scroll-overlay .btn  {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
    box-shadow: 0px 0px 15px rgba(0,0,0,.95);
    z-index:65;
}


.icon--status,
.icon--anlagen {
    position: relative;
    top:.25rem;
    font-size:1.25rem;
    line-height:1.25rem;
    margin-right:.5rem;
}

.state-icon-container .icon--status,
.anlagen-icon-container .icon--anlagen {
    transform: translateX(-50%);
    left: 50%;
}

.icon--status.icon-route {
    font-size:1.5rem;
    margin-left:-.125rem;
}

.legend-icon.icon--status {
    margin-right: 0;
    font-size: 1rem;
    &.icon-route {
        font-size: 1.25rem;
    }
}

.img-shadow {
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    transition: all .2s linear;
}
.img-shadow:hover,
.img-shadow:focus {
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.33);
}

.img-responsive {
    width: 100%;
    max-width:100%;
}

.icon--blink{
    animation: blink 1s  linear infinite;
}
@keyframes blink {
    25%{
        opacity: 0.5;
    }
    50%{
        opacity: 0;
    }
    75%{
        opacity: 0.5;
    }
}