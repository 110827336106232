.magazin-slider__vertical-hl {
    position: absolute;
    bottom: 3rem;
    left: 10rem;
    z-index: 1;
    transform: rotate(-90deg) translateX(10%);
    opacity: .35;
    color: #000;
    font-size: 100px;
    font-family: sourcesanspro-bold-webfont,sans-serif;
    line-height: .8;
    text-transform: uppercase;
    transform-origin: left bottom;

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
        bottom: 8rem;
    }

    @media screen and (min-width: 1440px) and (max-width: 1599px) {
        transform: rotate(-90deg) translateX(50%);
        bottom: -2rem;
    }

    @media screen and (min-width: 1600px) {
        transform: rotate(-90deg) translateX(80%);
        bottom: -4rem;
    }

    @media screen and (max-width: 767px) {
        font-size: 55px;
        left: 8rem;
        bottom: auto;
        top: 200px;
    }
}



.magazin-slide-wrap {
    position: relative;
    @media screen and (max-width: 767px) {
        margin:0 -1rem;
    }
}


@media screen and (min-width: 768px) {
    .magazin-slide-wrap:before {
        content:'';
        display: block;
        position: absolute;
        left:0;
        right:0;top:100px;
        bottom:100px;
        background:var(--color-light-grey);
    }
}

.magazin-slider-teaser__body {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding:30px 30px 90px 30px;

    @media screen and (min-width: 768px) {
        min-height: 470px;
        padding: 60px 60px 0 60px;
    }

    @media screen and (min-width: 1600px) {
        padding: 120px 120px 0 120px;
        min-height: 828px;
    }
}


.slider-count.slider-count--magazin {
    position:absolute;
    right:20px;
    bottom:5px;

    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        right:40px;
        bottom:40px;
    }

    @media screen and (min-width: 1600px) {
        right:120px;
        bottom:120px;
    }
}