.form {
    width: 100%;
    position: relative;
}
.form--narrow {
    margin:0 auto;
    max-width:440px;
}
.form--medium {
    margin:0 auto;
    max-width:680px;
}
.form-group {
    position: relative;
    margin-bottom:1rem;
    @media screen and (min-width: 768px) {
        margin-bottom:2.25rem;
    }
}
.form-group .checkbox.inline-checkbox p {
    display: inline;
    margin-left: calc(8rem / 16);
}

.form-group--datepicker:before {
    content:var(--icon-kalender);
    font-family: iconfont;
    color:var(--color-primary);
    display: block;
    position: absolute;
    right:1rem;
    top:.75rem;
    pointer-events: none;
}
.form-group-inline {
    display: inline-block;
}
.form-control--narrow {
    width:100%;
    max-width:220px;
}
.form-control {
    height:2.75rem;
    padding:.5rem .75rem;
    font-size:inherit;
    outline:0;

    @media screen and (min-width: 768px) {
        height:3.375rem;
        padding:1rem 1.25rem .875rem 1.25rem;
    }
}
select.form-control {
    @media screen and (min-width: 768px) {
        padding-top:.75rem;
    }
}
.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}