.interest-teaser {
    position: relative;
}
.interest-teaser:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    height:120px;
    z-index: 5;
    opacity: .8;
    transition: all .2s linear;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
@media screen and (min-width: 768px) {
    .interest-teaser:hover:after {
        opacity: 1;
    }
}
.interest-teaser__title {
    z-index: 10;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top: 0;
    padding: 2.5rem;
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
    color:#fff;
    font-size: 1.5rem;
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        line-height:1.25;
        font-size: 1.125rem;
        padding: 1.5rem;
    }
}
.interest-teaser__title:hover{
    color: #FFFFFF;
}