.opening-hours{
    border: 2px solid var(--color-text-muted);
    border-bottom: 1px solid var(--color-text-muted);
    position: relative;
    padding:1.5rem 0 0 0;
    @media screen and (max-width: 767px){
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}
.opening-hours__logo{
    position: relative;
    margin:0 auto;
}
.opening-hours__icon-web {
    transform: translateY(20%);
    color: var(--color-default);
    margin-right: 0.5rem;
    font-size: 1.125rem;
}
.opening-hours__table--head {
    background-color: var(--color-dark-grey);
    text-align: left;
    padding: 1rem;
    margin-left: -2px;
    margin-right: -2px;
}

.opening-hours__table--cell {
    border-bottom: 1px solid var(--color-text-muted);
    text-align: left;
    padding: 1rem;
}